import React, { useState } from 'react'

export default function TextInput(props) {
  const [memeText, setMemeText] = useState("");

  const addWord = () => {
    props.addWord(memeText)
    setMemeText("")
  }

  return (
    <div style={{ margin: "1rem" }}>
      <div>
        <div style={{ display: "grid", gap: "1rem" }}>
          <input
            value={memeText}
            className="nubInput"
            placeholder={"Enter text"}
            type="text"
            onChange={(x) => setMemeText(x.target.value)} />

          <div style={{ display: "grid", justifyContent: "center", alignContent: "center" }}>
            <button
              disabled={!memeText}
              style={{
                border: "1px solid white",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                background: "#b7d9fe",
                padding: ".5rem",
                borderRadius: "1rem",
                color: "white",
                fontWeight: "bolder",
                cursor: "pointer"
              }}
              onClick={addWord}>Add Text</button>
          </div>
        </div>
      </div>

      {props.text.length > 0 && (
        <div style={{ height: "100%", maxHeight: "50px", overflow: "auto" }}>
          {props.text.length > 0 && props.text.map(text =>
            <div style={{
              border: "1px solid white",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              background: "#b7d9fe",
              padding: ".5rem",
              borderRadius: "1rem",
              color: "white",
              fontWeight: "bolder"
            }}>
              {text}
              <button
                style={{ background: "#8097B1", border: "none", color: "white", display: "grid", borderRadius: ".5rem", padding: ".5rem" }}
                onClick={() => props.checkForText(text)}>X</button>
            </div>
          )}
        </div>
      )}

    </div>
  )
}
