import React, { useEffect, useState, useRef } from 'react';
import { Stage, Layer, Text, Rect, Transformer, Image } from 'react-konva';
import "gifler";

export default function MemeGenerator(props) {
  const [selectedShape, setSelectedShape] = useState(null);
  const stageRef = useRef();
  const transformerRef = useRef();
  const textRef = useRef();
  const trRef = useRef();

  const handleDragStart = (e) => {
    setSelectedShape(e.target);
  };

  const handleDragEnd = (e) => {
    setSelectedShape(null);
  };

  useEffect(() => {
    if (selectedShape) {
      const originalWidth = selectedShape.width();
      const originalHeight = selectedShape.height();
      let minWidth = 50; // Minimum width for the shape
      let minHeight = 50; // Minimum height for the shape

      transformerRef.current?.nodes([selectedShape]);

      transformerRef.current?.on('transform', () => {
        const scaleX = selectedShape.scaleX();
        const scaleY = selectedShape.scaleY();
        const newScale = Math.max(scaleX, scaleY); // Maintain aspect ratio

        // Check for minimum dimensions
        if (newScale * originalWidth < minWidth || newScale * originalHeight < minHeight) {
          return; // Prevent resizing below minimums
        }

        // Apply the new scale
        selectedShape.scaleX(newScale);
        selectedShape.scaleY(newScale);

        // Adjust position (optional, but recommended for a smoother UX)
        selectedShape.x(selectedShape.x() - (newScale - scaleX) * originalWidth / 2);
        selectedShape.y(selectedShape.y() - (newScale - scaleY) * originalHeight / 2);

        transformerRef.current?.getLayer().batchDraw(); // Update the layer
      });
    } else {
      transformerRef.current?.nodes([]);
    }
  }, [selectedShape]);

  useEffect(() => {
    if (textRef.current) {
      trRef.current?.nodes([textRef.current]);
      trRef.current?.getLayer().batchDraw();
    }
  }, [props.text]);

  useEffect(() => {
    props.setStageRef(stageRef)
  }, [stageRef])

  return (
    <>
      <div style={{ background: "#b7d9fe", display: "grid", justifyContent: "center", padding: "1rem", marginLeft: "auto", marginRight: "auto" }}>
        <Stage width={300} height={300} ref={stageRef}>
          <Layer>
            <Rect fill="white" x={0} y={0} width={500} height={500} />

            {props.images.length > 0 && props.images.map(img =>
              <Image
                image={img?.source}
                x={50}
                y={50}
                width={100}
                height={100}
                draggable
                onDragStart={handleDragStart}
                onDragEnd={handleDragEnd}
                onClick={(e) => setSelectedShape(e.target)}
                onTap={(e) => setSelectedShape(e.target)}
                rotation={0}
                setRotation={true}
              />
            )}

            {props.text && props.text.map(text =>
              <Text
                ref={textRef}
                text={text}
                fontSize={18}
                draggable
                onDragStart={handleDragStart}
                onDragEnd={handleDragEnd}
                onClick={(e) => setSelectedShape(e.target)}
                onTap={(e) => setSelectedShape(e.target)}
                fontFamily={"ui-sans-serifui-sans-serif,system-ui,sans-serif"}
                fontStyle={"bold"}
              />
            )}

            {selectedShape && (
              <Transformer
                ref={transformerRef}
                rotateEnabled={false}
                enabledAnchors={["top-left", "top-right", "bottom-left", "bottom-right"]}
                boundBoxFunc={(oldBox, newBox) => {
                  if (newBox.width < 5 || newBox.height < 5) {
                    return oldBox;
                  }
                  return newBox;
                }}
              />
            )}
          </Layer>
        </Stage>
      </div>
    </>
  )
}