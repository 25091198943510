import React, { useRef } from 'react';

export default function AddNub(props) {
  const fileInputRef = useRef(null);

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  const handleImageUpload = async (event) => {
    if (event?.target?.files?.length === 0) return;
    const files = event.target.files;
    const image = new window.Image();

    image.src = URL.createObjectURL(files[0]);
    image.onload = async () => {

      const response = await fetch(image.src);
      const blob = await response.blob();

      const reader = new FileReader();
      reader.onloadend = () => {
        let imageOption = {
          source: image,
          src: reader.result
        }

        props.addImageUpload(imageOption)
      };
      reader.readAsDataURL(blob);
    };
  };

  return (
    <>
      <div style={{ width: "100%", position: "absolute", bottom: 0, display: "flex", justifyContent: "center" }}>
        <input
          type="file"
          ref={fileInputRef}
          onChange={handleImageUpload}
          style={{ display: 'none' }}
        />

        <button
          style={{
            display: 'grid',
            width: "100%",
            padding: "1rem",
            background: "#b7d9fe",
            color: "white",
            cursor: "pointer",
            fontFamily: "Poppins",
            border: "1px #8097B1 solid"
          }}
          className="nub_text"
          onClick={handleButtonClick}>
          Upload Image
        </button>

        <button
          style={{
            display: 'grid',
            width: "100%",
            padding: "1rem",
            background: "#b7d9fe",
            color: "white",
            cursor: "pointer",
            fontFamily: "Poppins",
            border: "1px #8097B1 solid"
          }}
          className="nub_text"
          onClick={() => props.setSelectNub(!props.viewNubStatus)}>
          Select NUB
        </button>

        <button
          style={{
            display: 'grid',
            width: "100%",
            padding: "1rem",
            background: "#b7d9fe",
            color: "white",
            cursor: "pointer",
            fontFamily: "Poppins",
            border: "1px #8097B1 solid"
          }}
          onClick={props.handleDownload}>Download</button>
      </div>
    </>
  )
}
