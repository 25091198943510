import React from 'react';
import "../styles/ImageSlider.css";

export default function ImageSlider(props) {

  return (
    <div style={{ position: "absolute", bottom: 55, marginLeft: "1rem", marginRight: "1rem", background: "#b7d9fe", zIndex: 2, borderTopLeftRadius: "1rem", borderTopRightRadius: "1rem", left: 0, right: 0 }} className="nub_section">
      <div style={{ width: "100%", overflow: "auto" }}>
        <div style={{ padding: "1rem" }}>
          <div style={{ overflowY: "auto", display: "flex", gap: "1rem" }}>
            <div style={{ gap: "1rem", display: "flex", padding: ".25rem" }}>
              <button className="nub_button">
                <img className="nub_background" height={100} alt="" onClick={() => props.handleImageClick("https://i.imgur.com/jnkBHLl.png")} src={"https://i.imgur.com/jnkBHLl.png"}></img>
              </button>

              <button className="nub_button">
                <img className="nub_background" height={100} alt="" onClick={() => props.handleImageClick("https://i.imgur.com/y7guiSj.png")} src={"https://i.imgur.com/y7guiSj.png"}></img>
              </button>

              <button className="nub_button">
                <img className="nub_background" height={100} alt="" onClick={() => props.handleImageClick("https://i.imgur.com/NqAiO9J.png")} src={"https://i.imgur.com/NqAiO9J.png"}></img>
              </button>

              <button className="nub_button">
                <img className="nub_background" height={100} alt="" onClick={() => props.handleImageClick("https://i.imgur.com/4iFcXTQ.png")} src={"https://i.imgur.com/4iFcXTQ.png"}></img>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
