import { memeArray } from "./server";
// import { memeArrayAI } from "./server";

export async function searchMemes(tag) {
  const memesData = memeArray

  let memesFound = [];
  for (let i = 0; i < memesData.length; i++) {
    memesData[i].tags.forEach(async (data) => {
      if (data.includes(tag.toLowerCase())) {
        const alreadyAdded = memesFound.filter((source) => source.source === memesData[i].source)

        let memeDetails = {
          source: memesData[i].source,
          tags: memesData[i].tags,
          type: memesData[i].type,
          downloads: memesData[i].downloads,
          copied: memesData[i].copied,
          id: memesData[i].id
        }

        if (alreadyAdded.length === 0) {
          memesFound.push(memeDetails)
        }
      }
    })
  }
  const dataFound = await Promise.all(memesFound)
  return dataFound;
}