import "../App.css"
import React, { useEffect, useState, useRef, Player } from 'react';
import { motion } from "framer-motion";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { fetchMemes, deleteMeme, updateDocument } from '../server';

import loadingImage from "../images/loading-gif.gif";
import ImageSlider from "./ImageSlider";

export default function Nubmemes() {
  const gridRef = useRef();
  const [memesImages, setMemesImages] = useState([]);
  const [password, setPassword] = useState("");
  const [showMemes, setShowMemes] = useState(false);

  async function filterMostDownloads() {
    let memesArray = [];
    const memes = await fetchMemes();
    for (let i = 0; i < memes.length; i++) {
      memesArray.push(memes[i])
    }
    const mostDownloads = memesArray.sort(function (a, b) { return b.downloads - a.downloads });
    const totalDownloads = await Promise.all(mostDownloads);
    setMemesImages(totalDownloads);
  }

  async function enterPrompt(id) {
    let userInput = prompt("Enter a prompt: ")
    await updateDocument(id, userInput)
  }

  async function enterPassword() {
    if (password === "1574030504") {
      setShowMemes(!showMemes)
    } else {
      alert("User not permitted to access this page.")
    }
  }

  useEffect(() => {
    filterMostDownloads()
  }, [])

  useEffect(() => {
    gridRef?.current?.style?.setProperty('--min-image-size', `200px`);
  }, [showMemes])

  return (
    <>
      {memesImages.length > 0 && (
        <ImageSlider gridRef={gridRef} />
      )}

      {memesImages.length > 0 && showMemes === true ? (
        <div ref={gridRef} className="memesGrid">
          {memesImages.length > 0 && memesImages.map(memes =>
            <>
              {memes?.type === "video/mp4" ? (
                <motion.div
                  animate={{ y: 25 }}
                  transition={{ type: "spring", stiffness: 100 }}
                  viewport={{ once: false }}
                  whileHover={{ scale: 1.01 }}
                  style={{ display: "grid", alignItems: "center", justifyContent: "center" }}
                >
                  <video className="memesCard" loop controls>
                    <source src={memes.source} type="video/mp4" />
                  </video>
                  {/* <video className="memesCard" controls autoPlay loop muted={true} src={memes.source}></video> */}
                  <div style={{ display: "flex", gap: "1rem", padding: ".5rem" }}>
                    <motion.button
                      animate={{ x: 0 }}
                      initial={{ x: -25 }}
                      transition={{ type: "spring", stiffness: 100 }}
                      viewport={{ once: false }}
                      whileHover={{ scale: 1.01 }}
                      whileTap={{ scale: 0.9 }}
                      title="Copy"
                      className="nubButton"
                      style={{ background: "red" }}
                      onClick={() => deleteMeme(memes.id)}>
                      Delete
                    </motion.button>

                    <motion.button
                      animate={{ x: 0 }}
                      initial={{ x: -25 }}
                      transition={{ type: "spring", stiffness: 100 }}
                      viewport={{ once: false }}
                      whileHover={{ scale: 1.01 }}
                      whileTap={{ scale: 0.9 }}
                      title="Copy"
                      className="nubButton"
                      onClick={() => enterPrompt(memes.id)}>
                      Edit
                    </motion.button>
                  </div>
                </motion.div>
              ) : (
                <motion.div
                  animate={{ y: 25 }}
                  transition={{ type: "spring", stiffness: 100 }}
                  viewport={{ once: false }}
                  whileHover={{ scale: 1.01 }}
                  style={{ display: "grid", alignItems: "center", justifyContent: "center" }}
                >
                  <LazyLoadImage
                    alt={`${memes.tags[1]}`}
                    effect="blur"
                    src={memes.source}
                    delayTime={500}
                    placeholderSrc={loadingImage}
                    className="memesCard"
                    style={{ aspectRatio: "1/1" }}
                  />
                  <div style={{ display: "flex", gap: "1rem", padding: ".5rem" }}>
                    <motion.button
                      animate={{ x: 0 }}
                      initial={{ x: -25 }}
                      transition={{ type: "spring", stiffness: 100 }}
                      viewport={{ once: false }}
                      whileHover={{ scale: 1.01 }}
                      whileTap={{ scale: 0.9 }}
                      title="Copy"
                      className="nubButton"
                      style={{ background: "red", color: "white" }}
                      onClick={() => deleteMeme(memes.id)}>
                      Delete
                    </motion.button>

                    <motion.button
                      animate={{ x: 0 }}
                      initial={{ x: -25 }}
                      transition={{ type: "spring", stiffness: 100 }}
                      viewport={{ once: false }}
                      whileHover={{ scale: 1.01 }}
                      whileTap={{ scale: 0.9 }}
                      title="Copy"
                      className="nubButton"
                      onClick={() => enterPrompt(memes.id)}>
                      Edit
                    </motion.button>
                  </div>
                </motion.div>
              )}
            </>
          )}
        </div>
      ) : (
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", gap: "1rem" }}>
          <input onChange={(x) => setPassword(x.target.value)} className="nubInput" placeholder='Enter password'></input>
          <div><button onClick={() => enterPassword()} style={{ padding: ".5rem" }} className="nubButton">Enter</button></div>
        </div>
      )
      }
    </>
  )
}
