import React, { useState } from 'react';
import MemeGenerator from "../utils/MemeGenerator";
import ImageSlider from "../utils/ImageSlider";
import AddNub from "../utils/AddNub";
import TextInput from '../utils/TextInput';

export default function Generator() {
  const [text, setText] = useState([]);
  const [images, setImage] = useState([]);
  const [selectNub, setSelectNub] = useState(false);
  const [stageRef, setStageRef] = useState(null);

  async function checkForImage(selectedImage) {
    const textChecked = images.filter(t => t.src !== selectedImage)
    setImage(textChecked)
  }

  const addWord = (newWord) => {
    setText(prevWords => [...prevWords, newWord]);
  };

  async function checkForText(selectedText) {
    const textChecked = text.filter(t => t !== selectedText)
    setText(textChecked)
  }

  const handleImageClick = async (img) => {
    fetch(img)
      .then(response => response.blob())
      .then(blob => {
        const img2 = new window.Image();
        img2.src = URL.createObjectURL(blob);

        img2.onload = () => {
          let imageOption = {
            source: img2,
            src: img
          }

          setImage(images => [...images, imageOption])
          URL.revokeObjectURL(img2.src);
        };
      });
    setSelectNub(!selectNub)
  }

  const handleDownload = () => {
    if (stageRef.current) {
      const dataURL = stageRef.current.toDataURL({ pixelRatio: 3 });
      const link = document.createElement('a');
      link.download = 'konva_export.png';
      link.href = dataURL;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  return (
    <div style={{ height: "100%", display: "grid", justifyContent: "center", alignContent: "center", alignItems: "center" }}>
      <MemeGenerator
        text={text}
        images={images}
        setStageRef={setStageRef}
      />

      {images.length > 0 && (
        <div style={{ display: "flex", width: "100%", overflow: "auto", alignItems: "center" }}>
          {images.length > 0 && images.map(imgs =>
            <div style={{
              border: "1px solid white",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              background: "#b7d9fe",
              padding: ".5rem",
              borderRadius: "1rem",
              color: "white",
              fontWeight: "bolder",
              gap: "1rem"
            }}>
              <img alt="" height={50} src={imgs?.src}></img>
              <button
                style={{ background: "#8097B1", border: "none", color: "white", display: "grid", borderRadius: ".5rem", padding: ".5rem" }}
                onClick={() => checkForImage(imgs?.src)}>X</button>
            </div>
          )}
        </div>
      )}

      {selectNub === true && (
        <ImageSlider
          handleImageClick={(x) => handleImageClick(x)}
        />
      )}

      <AddNub
        images={images}
        addImageUpload={(x) => setImage(imageProps => [...imageProps, x])}
        checkForImage={checkForImage}
        setSelectNub={(x) => setSelectNub(x)}
        viewNubStatus={selectNub}
        handleDownload={handleDownload}
      />

      <TextInput text={text} addWord={addWord} checkForText={checkForText} />
    </div>
  );
}
