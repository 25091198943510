import React, { useState, useRef, useEffect } from 'react';
import Slider from 'rc-slider';
import mascot from "../images/mascot.png";
import 'rc-slider/assets/index.css';

export default function ImageSlider(props) {
  const [imageSize, setImageSize] = useState(250); // Start with CSS minmax value

  useEffect(() => {
    if (!props.gridRef.current) return;
    props.gridRef.current.style.setProperty('--min-image-size', `${imageSize}px`);
  }, [imageSize]);

  useEffect(() => {
    if (!props.gridRef.current) return;
    props.gridRef.current.style.setProperty('--min-image-size', `200px`);
  }, [props?.gridRef?.current]);

  useEffect(() => {
    setImageSize(200)
  }, [])

  return (
    <div>
      <Slider
        styles={{
          handle: {
            backgroundImage: `url(${mascot})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            height: 25,
            width: 25,
            top: -3,
            borderRadius: 0,
            border: "none",
          },
          track: {
            background: "#9ecdff"
          }
        }}

        style={{ width: 250, display: "grid", justifyContent: "center", marginLeft: "auto", marginRight: "auto" }}
        value={imageSize}
        onChange={(x) => setImageSize(x)}
        min={100}
        max={300}
      />
    </div>
  )
}
