import React, { useRef, useState, useEffect } from "react";
import { Stage, Layer, Image } from "react-konva";
import { saveAs } from 'file-saver';
import Slider from 'rc-slider';
import "gifler";

export default function Humper() {
  const imageRef = React.useRef(null);
  const [faceImage, setFaceImage] = useState([]);
  const fileInputRef = useRef(null);
  const [imageSize, setImageSize] = useState(250);
  const stageRef = useRef(null);

  useEffect(() => {
    setImageSize(200)
  }, [])

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  const handleImageUpload = async (event) => {
    const files = event.target.files;
    const image = new window.Image();

    image.src = URL.createObjectURL(files[0]);
    image.onload = async () => {

      const response = await fetch(image.src);
      const blob = await response.blob();

      console.log(image.height)
      console.log(image.width)
      const reader = new FileReader();
      reader.onloadend = () => {
        let imageOption = {
          source: image,
          src: reader.result,
          height: image.height,
          width: image.width
        }

        setFaceImage(imageOption)
      };
      reader.readAsDataURL(blob);
    };
  };

  const handleDownload = async () => {
    const response = await fetch(imageRef.current);
    const blob = await response.blob();
    saveAs(blob, `nub_meme_test.gif`);
  }

  const GIF = ({ src }) => {
    const canvas = React.useMemo(() => {
      const node = document.createElement("canvas");
      return node;
    }, []);

    React.useEffect(() => {
      let anim;
      window.gifler(src).get(a => {
        anim = a;
        anim.animateInCanvas(canvas);
        anim.onDrawFrame = (ctx, frame) => {
          ctx.drawImage(frame.buffer, frame.x, frame.y);
          imageRef?.current.getLayer().draw();
        };
      });
      return () => anim?.stop();
    }, [src, canvas]);

    return (
      <>
        <Image draggable={true} height={faceImage.height} width={faceImage.width} image={faceImage.source} ref={imageRef} />
        <Image rotation={50} draggable={true} height={imageSize} width={imageSize} image={canvas} ref={imageRef} />
      </>
    );
  };


  return (
    <>
      <div style={{ display: "grid", justifyContent: "center", height: "100%", minHeight: "91dvh" }}>
        <div style={{ display: "grid", justifyContent: "center", marginTop: "auto", marginBottom: "auto" }}>

          {faceImage.height > 0 && (
            <>
              <div style={{ padding: "1rem" }}>
                <Slider
                  styles={{
                    handle: {
                      background: "transparent",
                      backgroundImage: `url("https://i.imgur.com/DD7tfQr.gif")`,
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                      height: 45,
                      width: 45,
                      top: -20,
                      borderRadius: 0,
                      border: "none",
                    },
                    track: {
                      background: "#9ecdff"
                    }
                  }}

                  style={{ width: 250, display: "grid", justifyContent: "center", marginLeft: "auto", marginRight: "auto" }}
                  value={imageSize}
                  onChange={(x) => setImageSize(x)}
                  min={100}
                  max={300}
                />
              </div>

              <div style={{ display: "grid", justifyContent: "center", marginTop: "auto", marginBottom: "auto" }}>
                <Stage ref={stageRef} width={500} height={500}>
                  <Layer>
                    <GIF src="https://i.imgur.com/DD7tfQr.gif" />
                  </Layer>
                </Stage>
              </div>
            </>
          )}

          {faceImage?.source !== "" && (
            <div style={{ display: "grid", justifyContent: "center" }}>
              <input
                type="file"
                ref={fileInputRef}
                onChange={handleImageUpload}
                style={{ display: 'none' }}
              />

              <button
                style={{ display: "flex", textTransform: "uppercase", alignItems: "center", gap: "1rem", padding: ".25rem", background: "transparent", border: "none", cursor: "pointer", fontSize: "1.5rem" }}
                onClick={handleButtonClick}>
                Hump Me
                <img
                  alt=""
                  style={{ borderRadius: ".25rem" }}
                  height={25}
                  src={"https://i.imgur.com/rJfJcGB.png"}></img>
              </button>

              <button
                style={{ display: "flex", textTransform: "uppercase", alignItems: "center", gap: "1rem", padding: ".25rem", background: "transparent", border: "none", cursor: "pointer", fontSize: "1.5rem" }}
                onClick={handleDownload}>
                Download
                <img
                  alt=""
                  style={{ borderRadius: ".25rem" }}
                  height={25}
                  src={"https://i.imgur.com/rJfJcGB.png"}></img>
              </button>
            </div>
          )}
        </div>
      </div>
    </>
  )
}