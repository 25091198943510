import React, { useEffect, useState, useRef, Player } from 'react';
import { motion, useCycle } from "framer-motion";
// import { Helmet } from 'react-helmet';

import { saveAs } from 'file-saver';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; // Import the styles

import { searchMemes } from '../memeAiSearch';
import { fetchMemesAI, incrementCopies, incrementDownloads } from '../server';

import tv from "../images/tv.png";
import static1 from "../images/static.gif";
import saveButton from "../images/save button.png";
import rightArrow from "../images/next button.png";
import leftArrow from "../images/previous button.png";
import hands from "../images/search.png";
import mascot from "../images/mascot.png";

import MemesGrid from './MemesGrid';
import ImageSlider from './ImageSlider';

export default function NubAI(props) {
  const [memesImages, setMemesImages] = useState([]);
  const [imageCopied, setImageCopied] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [activeTags, setActiveTags] = useState([]);
  const [hideMature, setHideMature] = useState(false);
  const [viewRandom, setViewRandom] = useState(false);
  const [randomImage, setRandomImage] = useState("");
  const [previousImage, setPreviousImage] = useState("");
  const gridRef = useRef(null);

  async function chooseRandomTags() {
    let memeTags = [];
    const memesArray = await fetchMemesAI();

    for (let i = 0; i < memesArray.length; i++) {
      memesArray[i].tags.forEach((tags) => {
        memeTags.push(tags)
      })
    }

    const memeTagsLoaded = await Promise.all(memeTags)
    if (hideMature === true) {
      const newImages = memeTagsLoaded.filter(tag => !tag.includes("18+"));
      const memeTags1 = newImages[Math.floor(Math.random() * newImages.length)];
      await findMeme(memeTags1);
    } else {
      const memeTags1 = memeTagsLoaded[Math.floor(Math.random() * memeTagsLoaded.length)];
      await findMeme(memeTags1);
    }
  }

  async function filterMostDownloads() {
    let memesArray = [];
    const memes = await fetchMemesAI();
    console.log(memes)
    for (let i = 0; i < memes.length; i++) {
      memesArray.push(memes[i])
    }
    const mostDownloads = memesArray.sort(function (a, b) { return b.downloads - a.downloads });
    const totalDownloads = await Promise.all(mostDownloads);
    setMemesImages(totalDownloads);
  }

  async function findMeme(tag) {
    const memesFound = await searchMemes(tag);
    setMemesImages([]);
    if (hideMature === true) {
      const newImages = memesFound.filter(image => !image.tags.includes("18+"));
      setMemesImages(newImages);
      setSearchTerm(tag);
      setViewRandom(false);
    } else {
      setSearchTerm(tag);
      setMemesImages(memesFound);
      setViewRandom(false);
    }
  }

  const handleDownloadImage = async (imageUrl, name, type, id) => {
    try {
      if (type === "video/mp4") {
        const response = await fetch(imageUrl, { responseType: 'blob' });
        const blob = await response.blob();
        saveAs(blob, `${name}.mp4`);
      } else if (type === "image/jpg") {
        const response = await fetch(imageUrl);
        const blob = await response.blob();
        saveAs(blob, `nub_meme_${name}.jpg`); // Adjust filename as needed
      } else if (type === "image/gif") {
        const response = await fetch(imageUrl);
        const blob = await response.blob();
        saveAs(blob, `nub_meme_${name}.gif`); // Adjust filename as needed
      } else if (type === "image/jpeg") {
        const response = await fetch(imageUrl);
        const blob = await response.blob();
        saveAs(blob, `nub_meme_${name}.jpeg`); // Adjust filename as needed
      } else if (type === "image/png") {
        const response = await fetch(imageUrl);
        const blob = await response.blob();
        saveAs(blob, `nub_meme_${name}.jpeg`); // Adjust filename as needed
      }
      incrementDownloads(id)
    } catch (error) {
      console.error('Error downloading image:', error);
    }
  };

  const handleImageFetch = async (imageUrl, id) => {
    try {
      await toast('Nub copied!', {
        icon: `${mascot}`
      });

      const response = await fetch(imageUrl);
      const blob = await response.blob(); // Fetch the image as a Blob

      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');

      const image = new Image();
      image.crossOrigin = "anonymous"; // May or may not work
      image.onload = async () => { // Process after image loads
        canvas.width = image.width;
        canvas.height = image.height;
        ctx.drawImage(image, 0, 0);

        canvas.toBlob((newBlob) => {
          navigator.clipboard.write([
            new ClipboardItem({ [newBlob.type]: newBlob })
          ]).then(() => {
            setImageCopied(!imageCopied)
            console.log('Image copied to clipboard!');
          }).catch(error => {
            console.error('Error copying image:', error);
          });
        });
      }
      image.src = imageUrl;
      incrementCopies(id)// Start loading the image
    } catch (error) {
      console.error('Error fetching image:', error);
    }
  };

  function filterImagesByTag(images) {
    const newImages = images.filter(image => !image.tags.includes("18+"));
    setHideMature(!hideMature);
    setMemesImages(newImages);
  }

  async function showEverything() {
    const images = await fetchMemesAI();
    setHideMature(!hideMature);
    setMemesImages(images);
  }

  async function chooseRandom() {
    const images = await fetchMemesAI()
    setSearchTerm("")
    setPreviousImage(randomImage)

    let image = {
      source: static1,
      type: "image/gif"
    };

    setRandomImage(image)

    setViewRandom(true);
    setTimeout(() => {
      const randomImage = images[Math.floor(Math.random() * images.length)];
      setRandomImage(randomImage)
    }, 1000)
  }

  async function showPrevious() {
    let image = {
      source: static1,
      type: "image/gif"
    };

    setRandomImage(image)

    if (!previousImage) {
      const images = await fetchMemesAI()
      setSearchTerm("")
      setPreviousImage(randomImage)

      let image = {
        source: static1,
        type: "image/gif"
      };

      setRandomImage(image)

      setViewRandom(true);
      setTimeout(() => {
        const randomImage = images[Math.floor(Math.random() * images.length)];
        setRandomImage(randomImage)
      }, 1000)
      return;
    }

    setTimeout(() => {
      setRandomImage(previousImage)
    }, 1000)
  }

  useEffect(() => {
    function getMemes() {
      console.log("Checking memes")
      filterMostDownloads()
    }
    getMemes()
  }, [])

  useEffect(() => {
    let memeTags = [];
    for (let i = 0; i < memesImages.length; i++) {
      memesImages[i].tags.map((tags) => {
        memeTags.push(tags)
      });
    }

    const uniqueWords = memeTags.filter((word, index) => memeTags.indexOf(word) === index);
    setActiveTags(uniqueWords);
  }, [memesImages])

  useEffect(() => {
    async function refreshMemes() {
      if (props.refresh === true) {
        props.setRefresh(false)
        setSearchTerm("")
        setHideMature(false)
        setViewRandom(false)
        filterMostDownloads()
      }
    }
    refreshMemes()
  }, [props?.refresh])

  return (
    <div>
      <ToastContainer
        icon={mascot}
        hideProgressBar={true}
        closeButton={false}
        autoClose={800}
        toastStyle={{
          color: "white",
          background: "#9ecdff",
          fontFamily: "Poppins"
        }}
      />

      <div style={{ display: "flex", justifyContent: "center", gap: ".25rem", alignItems: "center" }}>
        <div style={{ position: "relative" }}>
          <img
            style={{ position: "absolute", top: "-20px", left: "25px", cursor: "pointer" }}
            height={30}
            alt=""
            onClick={() => chooseRandomTags()}
            src={hands}>
          </img>
          <input
            value={searchTerm}
            onChange={(x) => findMeme(x.target.value)}
            placeholder={searchTerm ? searchTerm : "find a nub"}
            className="nubInput"
            style={{ zIndex: 2, maxWidth: "200px" }}
          ></input>
        </div>

        <div style={{ display: "grid", gap: ".25rem" }}>
          <div style={{ display: "flex", gap: ".25rem" }}>
            <button
              onClick={() => chooseRandom()}
              className="nubButton">
              random
              <div></div>
              meme
            </button>

            {hideMature === false ? (
              <button
                onClick={() => filterImagesByTag(memesImages)}
                style={{ padding: ".5rem", display: "grid" }} className="nubButton">hide <div> </div>18+</button>
            ) : (
              <button
                onClick={() => showEverything()}
                style={{ padding: ".5rem", display: "grid" }} className="nubButton">show <div></div>18+</button>
            )}
          </div>
        </div>
      </div>

      {viewRandom === true && (
        <>
          <div className="image-container">
            {randomImage.type !== "video/mp4" && (
              <>
                <img
                  alt=""
                  src={randomImage.source}
                  className="base-image"></img>
                <img
                  alt=""
                  src={tv}
                  className="tv-overlay"></img>
              </>
            )}

            {randomImage.type === "video/mp4" && (
              <>
                <video
                  autoPlay={true}
                  controls={true}
                  loop={true}
                  muted={false}
                  src={randomImage.source}
                  className="base-image"></video>
                <img
                  alt=""
                  src={tv}
                  className="tv-overlay"></img>
              </>
            )}

            <div className="tv-button">
              <motion.button
                whileHover={{ scale: 1.0 }}
                whileTap={{ scale: 0.9 }}
                onClick={() => showPrevious()} style={{ background: "transparent", border: "transparent", zIndex: 2 }}>
                <img alt="" className="arrow-button left" src={leftArrow}></img>
              </motion.button>

              <motion.button
                whileHover={{ scale: 1.0 }}
                whileTap={{ scale: 0.9 }}
                onClick={() => handleDownloadImage(randomImage.source, randomImage.tags[0], randomImage.type, randomImage.id)} style={{ background: "transparent", border: "transparent", zIndex: 2 }}>
                <img alt="" className="save-button" src={saveButton}></img>
              </motion.button>

              <motion.button
                whileHover={{ scale: 1.0 }}
                whileTap={{ scale: 0.9 }}
                onClick={() => chooseRandom()} style={{ background: "transparent", border: "transparent", zIndex: 2 }}>
                <img alt="" className="arrow-button right" src={rightArrow}></img>
              </motion.button>
            </div>
          </div>
        </>
      )}

      {activeTags.length > 0 && viewRandom === false && (
        <div style={{ display: "grid", justifyContent: "center", padding: "1rem" }}>
          <div className="tagsSection">
            {activeTags.length > 0 && activeTags.map(memes =>
              <>
                <motion.button
                  animate={{ x: 0 }}
                  initial={{ x: -25 }}
                  transition={{ type: "spring", stiffness: 100 }}
                  viewport={{ once: false }}
                  whileHover={{ scale: 1.01 }}
                  whileTap={{ scale: 0.9 }}
                  onClick={() => findMeme(memes)}
                  className="nubTagButton"
                >
                  {memes}
                </motion.button>
              </>
            )}
          </div>
        </div>
      )}

      {viewRandom === false && memesImages.length > 0 && (
        <ImageSlider gridRef={gridRef} />
      )}

      <MemesGrid
        handleDownloadImage={(img, tags, type, id) => handleDownloadImage(img, tags, type, id)}
        viewRandom={viewRandom}
        gridRef={gridRef}
        memesImages={memesImages}
        handleImageFetch={(img, id) => handleImageFetch(img, id)}
      />
    </div>
  )
}
