import "../styles/Navbar.css";
import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import { motion } from 'framer-motion';

import mascot from "../images/mascot.png";
import shop from "../images/SHOP-2.gif"

export default function Navbar(props) {
  const images = require.context('../images/emojis', true, /\.(png|jpg|jpeg|svg)$/);
  const imageList = images.keys().map(images);
  const [emoji, setEmoji] = useState("");

  async function chooseRandomEmoji() {
    const randomEmoji = imageList[Math.floor(Math.random() * imageList.length)];
    setEmoji(randomEmoji)
  }

  useEffect(() => {
    setEmoji(imageList[0])
  }, [])

  return (
    <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
      <div onClick={() => props.setMemes(true)} style={{ display: "flex", fontSize: "1.5rem", alignItems: "center", gap: "1rem", cursor: "pointer" }}><div>
        <Link to="/">
          <img alt="" height={50} src={mascot}></img>
        </Link>
      </div>

        <div className="nav_title">
          NUBMEMES
        </div>
      </div>

      <div style={{ display: "flex", alignItems: "center" }}>
        <div>
          <motion.button
            title='Shop Nub'
            whileHover={{ scale: 1.0 }}
            whileTap={{ scale: 0.9 }}
            style={{ background: "transparent", border: 'transparent', cursor: "pointer" }} onClick={() => window.open("https://sillynub.com")}>
            <img alt="" height={75} src={shop}></img>
          </motion.button>
        </div>

        <div>
          <motion.button
            whileHover={{ scale: 1.0 }}
            whileTap={{ scale: 0.9 }}
            style={{ background: "transparent", border: 'transparent' }} onClick={() => chooseRandomEmoji()}>
            <img alt="" height={50} width={65} src={emoji}></img>
          </motion.button>
        </div>
      </div>
    </div >
  )
}
