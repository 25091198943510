import React from 'react'
import { motion } from 'framer-motion';
import { LazyLoadImage } from 'react-lazy-load-image-component';

import downloadIcon from "../images/logos/download_image.svg";
import copyIcon from "../images/logos/copy_image.svg";
import loadingImage from "../images/loading-gif.gif";

export default function MemesGrid(props) {
  return (
    <>
      {
        props.memesImages.length > 0 && props.viewRandom === false ? (
          <div>
            <div ref={props.gridRef} className="memesGrid">
              {props.memesImages.length > 0 && props.memesImages.map(memes =>
                <>
                  {memes?.type === "video/mp4" ? (
                    <motion.div
                      animate={{ y: 25 }}
                      transition={{ type: "spring", stiffness: 100 }}
                      viewport={{ once: false }}
                      whileHover={{ scale: 1.01 }}
                      style={{ display: "grid", alignItems: "center", justifyContent: "center" }}
                    >
                      <video className="memesCard" loop controls>
                        <source src={memes.source} type="video/mp4" />
                      </video>
                      {/* <video className="memesCard" controls autoPlay loop muted={true} src={memes.source}></video> */}
                      <div style={{ display: "flex", gap: "1rem", padding: ".5rem" }}>
                        <motion.button
                          animate={{ x: 0 }}
                          initial={{ x: -25 }}
                          transition={{ type: "spring", stiffness: 100 }}
                          viewport={{ once: false }}
                          whileHover={{ scale: 1.01 }}
                          whileTap={{ scale: 0.9 }}
                          title="Download"
                          className="nubButton"
                          onClick={() => props.handleDownloadImage(memes.source, memes.tags[0], memes.type, memes.id)}>
                          <img alt="" src={downloadIcon}></img>
                        </motion.button>

                        <motion.button
                          animate={{ x: 0 }}
                          initial={{ x: -25 }}
                          transition={{ type: "spring", stiffness: 100 }}
                          viewport={{ once: false }}
                          whileHover={{ scale: 1.01 }}
                          whileTap={{ scale: 0.9 }}
                          title="Copy"
                          className="nubButton"
                          onClick={() => props.handleImageFetch(memes.source, memes.id)}>
                          <img alt="" src={copyIcon}></img>
                        </motion.button>
                      </div>
                    </motion.div>
                  ) : (
                    <motion.div
                      animate={{ y: 25 }}
                      transition={{ type: "spring", stiffness: 100 }}
                      viewport={{ once: false }}
                      whileHover={{ scale: 1.01 }}
                      style={{ display: "grid", alignItems: "center", justifyContent: "center" }}
                    >
                      <LazyLoadImage
                        alt={`${memes.tags[1]}`}
                        effect="blur"
                        src={memes.source}
                        delayTime={500}
                        placeholderSrc={loadingImage}
                        className="memesCard"
                        style={{ aspectRatio: "1/1" }}
                      />
                      <div style={{ display: "flex", gap: "1rem", padding: ".5rem" }}>
                        <motion.button
                          animate={{ x: 0 }}
                          initial={{ x: -25 }}
                          transition={{ type: "spring", stiffness: 100 }}
                          viewport={{ once: false }}
                          whileHover={{ scale: 1.01 }}
                          whileTap={{ scale: 0.9 }}
                          title="Download"
                          className="nubButton"
                          onClick={() => props.handleDownloadImage(memes.source, memes.tags[0], memes.type, memes.id)}>
                          <div style={{ display: "flex", alignItems: "center", fontSize: ".75rem" }}>
                            <img alt="" src={downloadIcon}></img>
                          </div>
                        </motion.button>

                        <motion.button
                          animate={{ x: 0 }}
                          initial={{ x: -25 }}
                          transition={{ type: "spring", stiffness: 100 }}
                          viewport={{ once: false }}
                          whileHover={{ scale: 1.01 }}
                          whileTap={{ scale: 0.9 }}
                          title="Copy"
                          className="nubButton"
                          onClick={() => props.handleImageFetch(memes.source, memes.id)}>
                          <div style={{ display: "flex", alignItems: "center", fontSize: ".75rem", justifyContent: "space-between", padding: ".2rem" }}>
                            <img alt="" src={copyIcon}></img>
                          </div>
                        </motion.button>
                      </div>
                    </motion.div>
                  )}
                </>
              )}
            </div>
          </div>
        ) : (
          <>
            {props.viewRandom === false && (
              <div className="nubText">
                Enter a word to search for a meme.
              </div>
            )}
          </>
        )
      }
    </>
  )
}
