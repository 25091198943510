import "../App.css";
import React, { useState } from 'react';
import { ImgurClient } from 'imgur';
import { addDocument } from '../server';

export default function Portal() {
  const [preview, setPreview] = useState([]);
  const [tags, setTags] = useState([]);
  const [newTag, setNewTag] = useState("");
  const [password, setPassword] = useState(0);

  const handleGifChange = (event) => {
    for (let i = 0; i < event.target.files.length; i++) {
      const file = event.target.files[i];
      if (file.type.startsWith('image/gif')) {
        const clientId = 'ffcb44c64dfa36b';
        const client = new ImgurClient({ clientId });

        client.upload({
          image: file,
          type: 'file',
        }).then((response) => {
          setPreview(prevArray => [...prevArray, response.data.link]);
        }).catch((error) => {
          console.error('Imgur upload error:', error);
        });
      }
    }
  };

  async function addTags() {
    setTags(newTag.split(" "));
  }

  async function saveImage() {
    if (password === "1574030504") {
      let fileCount = 0;
      for (let i = 0; i < preview.length; i++) {
        await addDocument(tags, preview[i])
        console.log("Uploaded image: " + [i + 1])
        fileCount++;
      }
      await alert(`${fileCount} file(s) uploaded`)
      setPreview([])
      setTags([])
      setPassword([])
      setNewTag("")
    } else {
      alert("User not permitted!")
    }
  }

  return (
    <div style={{ display: "grid", justifyContent: "center", alignContent: "center", height: "90dvh", overflow: "auto", alignItems: "center", gap: "1rem" }}>
      <header style={{ display: "grid", alignItems: "center", textAlign: "center" }}>Upload Silly Gifs</header>
      {preview.length > 0 && (
        <div style={{ minWidth: "90%", display: "flex" }}>
          {preview && preview.map(images =>
            <img height={50} width={50} alt="gif_preview" style={{ maxWidth: 50, maxHeight: 50 }} className="memesCard" src={images}></img>
          )}
        </div>
      )}

      {preview.length === 0 && (
        <div className="gifInput">
          <input type="file" accept=".gif" multiple onChange={handleGifChange} />
        </div>
      )}

      {tags.length > 0 && (
        <div style={{ display: "flex", alignItems: "center", alignContent: "center", gap: "1rem" }}>
          <div><button onClick={() => setTags([])}>Clear</button> Tags:</div>
          <div style={{ display: "flex", gap: ".1rem" }}>
            {tags.map(tag =>
              <div style={{ background: "#b7d9fe", padding: ".15rem" }}>{tag}</div>
            )}
          </div>
        </div>
      )}

      <div style={{ display: "flex", gap: "1rem" }}>
        <input className="nubInput" onChange={(x) => setNewTag(x.target.value)} placeholder='Meme Tags'></input> <button className="nubButton" onClick={() => addTags()}>Set Tag(s)</button>
      </div>

      <div style={{ display: "flex" }}>
        <input className="nubInput" onChange={(x) => setPassword(x.target.value)} placeholder='password'></input>
      </div>

      {tags.length > 0 && preview.length > 0 && (
        <div style={{ display: "grid" }}>
          <button className="nubButton" onClick={() => saveImage()}>Upload</button>
        </div>
      )}
    </div>
  )
}
